import React, { useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import clsx from 'clsx';

import { Spinner, Accordion, Button, Tabs } from '@xchange/uikit';
import MainLayout from 'components/MainLayout';
import Search from 'components/Search';
import TableHeader from 'components/TableHeader';
import { WorkspaceContext } from 'workspace';
import BuyProcess from 'views/BuyProcess';
import { SortDashboardKeys } from 'enums';
import DashboardContext, { DashboardContextProvider } from './context';
import ListingContacts from './ListingContacts';
import ListingItemClosed from './ListingItemClosed';
import ListingItemSale from './ListingItemSale';
import ListingItemPurchase from './ListingItemPurchase';
import ListingItemUnderContract from './ListingItemUnderContract';
import CreateNewListing from './CreateNewListing';
import CardItem from './CardItem';
import { ReactComponent as MenuCardsSVG } from './menu-cards.svg';
import { ReactComponent as MenuListSVG } from './menu-list.svg';

const tableHeaderFields: { [key in DashboardTabKey]: { value: string; sort?: SortDashboardKeys; }[] } = {
  sales: [
    { value: 'Address', sort: SortDashboardKeys.Address },
    { value: 'Type', sort: SortDashboardKeys.PropertyType },
    { value: 'Listing Price', sort: SortDashboardKeys.CurrentPrice },
    { value: 'Listed', sort: SortDashboardKeys.PublishDate },
    { value: 'Expires', sort: SortDashboardKeys.ExpirationDate },
    { value: 'Offers', sort: SortDashboardKeys.OffersCount },
    { value: 'Commisions', sort: SortDashboardKeys.Commisions },
    { value: 'Expenses', sort: SortDashboardKeys.Expenses },
    { value: 'Actions' }
  ],
  purchases: [
    { value: 'Address', sort: SortDashboardKeys.Address },
    { value: 'Type', sort: SortDashboardKeys.PropertyType },
    { value: 'Listed', sort: SortDashboardKeys.Listed },
    { value: 'Offer Price', sort: SortDashboardKeys.OfferPrice },
    { value: 'Offer Date', sort: SortDashboardKeys.OfferDate },
    { value: 'Earnest Money', sort: SortDashboardKeys.EarnestMoney },
    { value: 'Financials', sort: SortDashboardKeys.Financials },
    { value: 'Closing Date', sort: SortDashboardKeys.ClosingDate },
    { value: 'Actions' }
  ],
  underContract: [
    { value: 'Address', sort: SortDashboardKeys.Address },
    { value: 'Type', sort: SortDashboardKeys.PropertyType },
    { value: 'Effective Date', sort: SortDashboardKeys.EffectiveDate },
    { value: 'Contract Price', sort: SortDashboardKeys.ContractPrice },
    { value: 'Earnest Money', sort: SortDashboardKeys.EarnestMoney },
    { value: 'Closing Cost Credits', sort: SortDashboardKeys.ClosingCostCredit },
    { value: 'Financials', sort: SortDashboardKeys.Financials },
    { value: 'Closing Date', sort: SortDashboardKeys.ClosingDate },
    { value: 'Commisions', sort: SortDashboardKeys.Commisions },
    { value: 'Actions' }
  ],
  closed: [
    { value: 'Address', sort: SortDashboardKeys.Address },
    { value: 'Type', sort: SortDashboardKeys.PropertyType },
    { value: 'Closing Date', sort: SortDashboardKeys.ClosingDate },
    { value: 'Sale Price', sort: SortDashboardKeys.ContractPrice },
    { value: 'Earnest Money', sort: SortDashboardKeys.EarnestMoney },
    { value: 'Financials', sort: SortDashboardKeys.Financials },
    { value: 'Post-closing escrows', sort: SortDashboardKeys.PostClosingEscrows },
    { value: 'Commissions', sort: SortDashboardKeys.Commisions },
    { value: 'Actions' }
  ]
};

type Tab = { key: DashboardTabKey; text: React.ReactNode; };

const tabDefaultQuery = {
  sales: {
    sort: SortDashboardKeys.PublishDate,
    order: 'desc'
  },
  purchases: {
    sort: SortDashboardKeys.OfferDate,
    order: 'desc'
  },
  underContract: {
    sort: SortDashboardKeys.ClosingDate,
    order: 'desc'
  },
  closed: {
    sort: SortDashboardKeys.ClosingDate,
    order: 'desc'
  }
};

const DashboardPage = () => {
  const { openWorkspace } = useContext(WorkspaceContext);
  const {
    listings,
    listingsTotal,
    setQueryFilters,
    query,
    handleSort,
    getListings,
    loading
  } = useContext(DashboardContext);
  const [createNewListingIsOpen, setCreateNewListingIsOpen] = useState<boolean>(false);
  const [createOfferIsOpen, setCreateOfferIsOpen] = useState<boolean>(false);
  const layout = useLayout();
  const { view: activeView, tab: activeTab, search: searchQuery } = query;

  const setSearchQuery = e => {
    setQueryFilters({ search: e.target.value });
  };

  const clearSearchQuery = () => {
    setQueryFilters({ search: '' });
  };

  const { sales, purchases, underContract, closed } = useMemo(() => {
    const sales: Listing[] = [];
    const purchases: Listing[] = [];
    const underContract: Listing[] = [];
    const closed: Listing[] = [];
    if (listings) {
      listings.forEach(item => {
        switch (item.status) {
          case 'Active':
          case 'Pending':
          case 'Canceled':
          case 'Expired':
          case 'Not Listed':
            sales.push(item);
            break;
          case 'Offer Placed':
            purchases.push(item);
            break;
          case 'Under Contract':
            underContract.push(item);
            break;
          case 'Closed':
            closed.push(item);
            break;
          default:
            break;
        }
      });
    }
    return { sales, purchases, underContract, closed };
  }, [listings]);

  const tabs: Tab[] = [
    {
      key: 'sales',
      text: (
        <span>
          Listings
          <span className="counter">{listingsTotal?.sales}</span>
        </span>
      )
    },
    {
      key: 'purchases',
      text: (
        <span>
          Purchases
          <span className="counter">{listingsTotal?.purchases}</span>
        </span>
      )
    },
    {
      key: 'underContract',
      text: (
        <span>
          Under Contract
          <span className="counter">{listingsTotal?.underContract}</span>
        </span>
      )
    },
    {
      key: 'closed',
      text: (
        <span>
          Closed Listings
          <span className="counter">{listingsTotal?.closed}</span>
        </span>
      )
    }
  ];

  const listView = useMemo(() => {
    if (!listings) return null;
    switch (activeTab) {
      case 'sales':
        return (
          <>
            {sales.length === 0 && <div className="no-data">no data</div>}
            {sales.map(listing => (
              <StyledAccordion label={<ListingItemSale data={listing} />} key={listing.id}>
                <div className="accordion-content__container">
                  <ListingContacts data={listing} />
                </div>
                <Button onClick={() => openWorkspace(listing.listingId)}>See more</Button>
              </StyledAccordion>
            ))}
          </>
        );
      case 'purchases':
        return (
          <>
            {purchases.length === 0 && <div className="no-data">no data</div>}
            {purchases.map(listing => (
              <StyledAccordion className={listing.mlsStatus === "ActiveUnderContract" ? "mark-salmon" : ""} label={<ListingItemPurchase data={listing} />} key={listing.id}>
                <div className="accordion-content__container">
                  <ListingContacts data={listing} />
                </div>
                <Button onClick={() => openWorkspace(listing.listingId)}>See more</Button>
              </StyledAccordion>
            ))}
          </>
        );
      case 'underContract':
        return (
          <>
            {underContract.length === 0 && <div className="no-data">no data</div>}
            {underContract.map(listing => (
              <StyledAccordion className={ (listing.mlsStatus !== "ActiveUnderContract" && listing.contractPrice) ? "mark-salmon" : ""} label={<ListingItemUnderContract data={listing} />} key={listing.id}>
                <div className="accordion-content__container">
                  <ListingContacts data={listing} />
                </div>
                <Button onClick={() => openWorkspace(listing.listingId)}>See more</Button>
              </StyledAccordion>
            ))}
          </>
        );
      case 'closed':
        return (
          <>
            {closed.length === 0 && <div className="no-data">no data</div>}
            {closed.map(listing => (
              <StyledAccordion label={<ListingItemClosed data={listing} />} key={listing.id}>
                <div className="accordion-content__container">
                  <ListingContacts data={listing} />
                </div>
                <Button onClick={() => openWorkspace(listing.listingId)}>See more</Button>
              </StyledAccordion>
            ))}
          </>
        );
      default:
        return null;
    }
  }, [activeTab, closed, listings, openWorkspace, purchases, sales, underContract]);

  const cardView = useMemo(() => {
    if (!listings) return null;
    let listingsArr: Listing[] = [];
    switch (activeTab) {
      case 'sales':
        listingsArr = sales;
        break;
      case 'purchases':
        listingsArr = purchases;
        break;
      case 'underContract':
        listingsArr = underContract;
        break;
      case 'closed':
        listingsArr = closed;
        break;
      default:
        break;
    }

    return (
      <div className="dahboard-page__card-view">
        {listingsArr.length === 0 && <div className="no-data">no data</div>}
        {listingsArr.map(item => (
          <CardItem key={item.id} data={item} type={activeTab!} onWorkspaceOpen={openWorkspace} />
        ))}
      </div>
    );
  }, [activeTab, closed, listings, openWorkspace, purchases, sales, underContract]);

  const createButton = (
    <div className="dahboard-page__creating-buttons">
      <Button className="dahboard-page__create-btn" onClick={() => setCreateOfferIsOpen(true)}>
        Start offer
      </Button>

      <Button className="dahboard-page__create-btn" onClick={() => setCreateNewListingIsOpen(true)}>
        Start listing
      </Button>
    </div>
  );

  return (
    <StyledMainLayout>
      <StyledDashboardPage className="dahboard-page">
        <div className="table-sticky">
          <div className="dahboard-page__header">
            <h4 className="dahboard-page__title">Dashboard</h4>
            {layout === 'desktop' && (
              <Search value={searchQuery} onChange={setSearchQuery} clear={clearSearchQuery} />
            )}
            {layout === 'desktop' && createButton}
            {layout === 'tablet' && (
              <div className="dahboard-page__search-filters">
                <Search value={searchQuery} onChange={setSearchQuery} clear={clearSearchQuery} />
                {createButton}
              </div>
            )}
            {layout === 'mobile' && createButton}
          </div>
          {layout === 'mobile' && (
            <div className="dahboard-page__search-filters">
              <Search value={searchQuery} onChange={setSearchQuery} clear={clearSearchQuery} />
            </div>
          )}
          <div className="dahboard-page__filters">
            <div className="dahboard-page__tabs scrollbar-custom">
              <StyledTabs
                activeTab={activeTab}
                onSelect={(_e, value) =>
                  setQueryFilters({ ...tabDefaultQuery[value.key], tab: value.key })
                }
                items={tabs}
                large
              />
            </div>
            <div className="dahboard-page__view-select">
              <Button
                className={clsx({ active: activeView === 'list' })}
                simple
                onClick={() => setQueryFilters({ view: 'list' })}>
                <MenuListSVG />
              </Button>
              <Button
                className={clsx({ active: activeView === 'cards' })}
                simple
                onClick={() => setQueryFilters({ view: 'cards' })}>
                <MenuCardsSVG />
              </Button>
            </div>
          </div>
          {(layout === 'desktop' || layout === 'tablet') && activeView === 'list' && (
            <TableHeader
              accordionHeader
              fields={tableHeaderFields[activeTab!]}
              onSort={handleSort}
              filters={{ sort: query.sort, order: query.order }}
            />
          )}
        </div>
        <div className="view-wrapper">
          {activeView === 'list' ? listView : cardView}
          {loading && (
            <div className="loading-layer">
              <Spinner />
            </div>
          )}
        </div>
      </StyledDashboardPage>
      <CreateNewListing
        open={createNewListingIsOpen}
        onClose={() => setCreateNewListingIsOpen(false)}
        onSuccess={() => getListings(query)}
      />
      <BuyProcess
        open={createOfferIsOpen}
        clickedOnNewOffer={createOfferIsOpen}
        onClose={() => setCreateOfferIsOpen(false)}
        onSave={() => setCreateOfferIsOpen(false)}
        onSuccess={() => getListings(query)}
      />
    </StyledMainLayout>
  );
};

export default props => (
  <DashboardContextProvider>
    <DashboardPage {...props} />
  </DashboardContextProvider>
);

const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
  height: 100%;
  margin-right: -20px;
  padding: 0 20px 20px 0;

  .view-wrapper {
    width: 100%;
    position: relative;
  }

  .mark-salmon{background:#ffeeec;}

  .loading-layer {
    background: #00000010;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;

    .spinner {
      position: fixed;
      top: 50%;
    }
  }

  .search {
    .input.search-input {
      margin: 0 12px 0 0;
    }
    .input {
      width: 300px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: -10px;
    padding: 0 12px 48px 0;

    .search {
      .input.search-input {
        width: 100%;
      }
    }
  }

  .dahboard-page {
    &__header {
      display: flex;
      margin: 0 0 28px;
      width: 100%;

      .search {
        margin: 0 12px 0 auto;
        width: 100%;
        max-width: 300px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0 auto 0 0;
    }
    &__filters {
      width: 100%;
      margin: 0 0 32px;
      align-items: center;
      padding-bottom: 5px;

      .dahboard-page__tabs {
        display: inline-block;
        overflow-x: auto;
        margin-bottom: 5px;
      }
    }
    &__creating-buttons {
      display: flex;
    }
    &__create-btn {
      min-width: 128px;
      margin-left: 12px;
    }
    &__manage-btn {
      margin-left: auto;
    }
    &__view-select {
      display: flex;
      align-items: center;
      margin-left: auto;
      float: right;

      .button {
        margin-left: 16px;

        &.active {
          fill: #8b8b8b;
          pointer-events: none;
        }
      }
    }
    &__card-view {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 24px;
      align-items: center;
    }
  }
  .search .input-wrapper .input-component {
    height: 32px;
  }

  .table-sticky {
    width: 100%;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1000;
  }

  .table-header {
    display: flex;
    &__item {
      flex: 1;
      &.address {
        flex: 2.5;
        padding: 0 16px 0 0;
      }
      &.actions {
        flex: 0.5;
        .value {
          display: none;
        }
      }
    }
  }
  .no-data {
    color: ${props => props.theme.colors.darkGray};
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .dahboard-page {
      &__create-btn {
        min-width: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
      }
      &__search-filters {
        display: flex;
        .search {
          width: 300px;
          margin: 0 12px 0 0;
        }
      }
      &__header {
        margin: 0 0 16px;
      }
      &__card-view {
        margin-top: 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 4px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .dahboard-page {
      &__header {
        margin: 0 0 12px;
      }
      &__filters {
        margin: 0;
      }
      &__tabs {
        padding-bottom: 8px;
      }
      &__create-btn {
        padding: 0 6px;
        margin-left: 8px;
        min-width: auto;
      }
      &__search-filters {
        display: flex;
        width: 100%;
        margin: 0 0 16px;
        .search {
          width: 100%;
          margin: 0;
          .input-wrapper {
            .input-component {
              height: 40px;
            }
          }
        }
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  padding: 0;
  flex-wrap: nowrap;

  .counter {
    margin: 0 0 0 4px;
    color: ${props => props.theme.colors.grayDark};
  }
  .tab-item {
    flex-shrink: 0;
    &.active {
      .counter {
        color: ${props => props.theme.colors.red};
      }
    }
  }
`;

const StyledMainLayout = styled(MainLayout)`
  .content {
    padding-bottom: 0;
    height: calc(100vh - 80px);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: calc(100vh - 52px);
  }
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;

  .accordion-head {
    padding-left: 8px;
  }

  .label {
    width: 100%;
  }
  .counter {
    display: none;
  }
  .accordion-content {
    margin: 0 0 16px 24px;
    &__container {
      margin: 12px 0 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0 16px;
    }
  }
  .menu-btn {
    .icon {
      fill: ${props => props.theme.colors.graphite};
    }
  }
  .table-header-info {
    &__item {
      &.actions {
        flex: 0.5;
        justify-content: flex-start;
        .label {
          display: none;
        }
        a {
          display: flex;
          align-items: center;
          color: ${props => props.theme.colors.red};
          text-decoration: none;
        }
        .icon {
          fill: ${props => props.theme.colors.red};
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .accordion-content {
      &__container {
        grid-template-columns: 1fr;
        grid-gap: 16px 0;
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .table-header-info {
      &__item {
        &.address {
          flex: 2.5;
          padding: 0 16px 0 0;
          overflow: hidden;
          * > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        &.actions {
          span {
            display: none;
          }
        }
      }
    }
  }
`;
